<template>
  <v-dialog
    v-model="show"
    max-width="30%"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="amber"
      >
        <v-toolbar-title>
          Alterar senha
          <span
            v-if="contact"
            class="body-2"
          >
            <i>{{ contact.name }}</i>
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <v-row>
          <v-col>
            <validation-observer ref="formChangePassword">
              <validation-provider
                v-slot="{ errors }"
                vid="password"
              >
                <v-text-field
                  v-model="password"
                  label="Nova senha"
                  :error-messages="errors"
                />
              </validation-provider>
            </validation-observer>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="amber accent-4"
          :loading="loading"
          outlined
          @click="changePassword"
        >
          <v-icon left>
            mdi-lock-reset
          </v-icon>
          Salvar Nova Senha
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import citiesApi from '@/api/cities'

  export default {


    props: {
      value: {
        type: Boolean,
        default: false,
      },
      cityId: {
        type: Number,
      },
      contact: {
        type: Object,
        default: () => { },
      },
    },

    data () {
      return {
        loading: false,
        password: null,
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    watch: {
      show (val) {
        if (!val) return

        this.password = null
      },
    },

    methods: {
      async changePassword () {
        try {
          this.loading = true
          this.$refs.formChangePassword.setErrors({})

          await citiesApi.changePasswordContact(this.cityId, this.contact.id, {
            password: this.password,
          })

          this.show = false
          this.$snackbar.show({ color: 'success', message: this.$messages._('success_change_password') })
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.formChangePassword.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },
    },

  }
</script>
